<template>
  <c-card :title="`Detail Pengguna ${data.name}`">
    <b-row>
      <b-col sm="12" md="6">
        <dl class="row">
          <dt class="col-sm-3">Username</dt>
          <dt class="col-sm-9">{{data.username}}</dt>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Nama Lengkap</dt>
          <dt class="col-sm-9">{{data.name}}</dt>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Jabatan</dt>
          <dt class="col-sm-9">{{data.user_group.name}}</dt>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Status</dt>
          <dt class="col-sm-9">{{data.is_active ? 'Aktif' : 'Tidak Aktif'}}</dt>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Dibuat Oleh</dt>
          <dt class="col-sm-9">{{data.user_create}}</dt>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Dibuat Tanggal</dt>
          <dt class="col-sm-9">{{data.createdAt | fulldate}}</dt>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Diubah Oleh</dt>
          <dt class="col-sm-9">{{data.user_update}}</dt>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Diubah Tanggal</dt>
          <dt class="col-sm-9">{{data.updatedAt | fulldate}}</dt>
        </dl>
      </b-col>
    </b-row>
  </c-card>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
export default {
  components: { CCard },
  data(){
    return {
      data:{
        user_group:{}
      },
      branchList:''
    }
  },
  methods:{
    async getData(){
      try {
        const id = this.$route.params.id
        const {data} = await this.$http.get(`v1/users/show`,{params:{id:id}})
        this.data = data
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>